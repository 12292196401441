<template>
  <sub-page >

    <template #default>
      <!-- Payment Block-->
      <ws-accordion
          :items="accordionItems"
          class="mt-5"
      >
        <template v-if="!SM" #action.payment>
          <v-btn rounded elevation="0" @click="checkout" dark class="noCaps mr-5" :color="wsATTENTION">
            <v-icon class="mr-2">mdi-basket-plus</v-icon>
            {{ $t('MakePayment') }}
          </v-btn>
        </template>
        <template #item.payment>

          <div class="px-5 pt-5  justify-space-between" :class="!SM ? 'd-flex ' : null">
            <h4 :class="SM ? 'text-center ' : null" >Продовження послуг:</h4>
            <ws-select
                v-model="entityData.duration"
                :items="paymentDurationSelect"
                :width="!SM ? '200px' : ''"
                :class="SM ? 'mt-3 ' : null"
                dense outlined hide-details
            />
          </div>
          <div class=" pb-5 pt-5 pr-1  pl-9 " :class="!SM ? 'd-flex justify-space-between ' : null" >
            <div></div>
            <h4  :class="!SM ? 'text-center ' : null" class="mr-5"> Всьго:   {{ getFinalPrice() }} USD</h4>
          </div>

          <div  v-if="SM" class="px-5 pb-5">
            <v-btn  block  elevation="0" @click="checkout" dark class="noCaps  " :color="wsATTENTION">
              <v-icon class="mr-2">mdi-basket-plus</v-icon>
              {{ $t('MakePayment') }}
            </v-btn>
          </div>








        </template>

      </ws-accordion>
      <!-- Overview Block-->
      <ws-accordion
          :items="paymentPlanItems"
          class="mt-5"
      >
        <template #action.desktop.paymentPlan>
          <v-btn @click="openChangePlan" :color="wsACCENT" elevation="0" dark rounded class="noCaps mr-5">
            {{ entity.business_plan_name }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <template #action.mobile.paymentPlan>
          <div class="px-6 pt-2 pl-13 pb-5">
            <v-btn block @click="openChangePlan" :color="wsACCENT" elevation="0" dark rounded class="noCaps mr-5">
              {{ entity.business_plan_name }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>

        </template>

        <template #action.desktop.date>

          <div class="text-no-wrap pr-6 ">
            <h5 class="text-right"  :style="`color : ${ entity.days_left > 0 ? '' : 'red'  }`">
              <v-icon :color="entity.days_left > 0 ? wsACCENT : 'red'">mdi-calendar</v-icon>
              {{ entity.active_date}}
            </h5>
            <h5 v-if="entity.days_left < 0" class="red--text  " style="line-height: 1.2">
              Прострочено на {{ Math.abs(entity.days_left) }} днів
            </h5>
          </div>
        </template>
        <template #action.mobile.date>

          <div class="px-6 pt-2 pl-13 pb-5">
            <h5  :style="`color : ${ entity.days_left > 0 ? '' : 'red'  }`">
              <v-icon :color="entity.days_left > 0 ? wsACCENT : 'red'">mdi-calendar</v-icon>
              {{ entity.active_date}}
            </h5>
            <h5 v-if="entity.days_left < 0" class="red--text" style="line-height: 1.2">
              Прострочено на {{ Math.abs(entity.days_left) }} днів
            </h5>
          </div>
        </template>

        <template #action.desktop.students>

          <div class="text-no-wrap pr-6 ">
            <h5 class="text-no-wrap text-right"
                :style="`color : ${ entity.student_profiles_total <= entity.student_profiles_quota ? '' : 'red'  }`"
            >
              <v-icon :color="entity.student_profiles_total <= entity.student_profiles_quota ? wsACCENT : 'red'">mdi-account-multiple</v-icon>
              {{ entity.student_profiles_total }} / {{ entity.student_profiles_quota }}
            </h5>
            <h5 v-if="entity.student_profiles_total > entity.student_profiles_quota" class="red--text  text-right" style="line-height: 1.2">
              Перебільшено на {{ entity.student_profiles_total - entity.student_profiles_quota }}
            </h5>
          </div>


        </template>
        <template #action.mobile.students>

          <div class="px-6 pt-2 pl-13 pb-5">
            <h5 :style="`color : ${ entity.student_profiles_total <= entity.student_profiles_quota ? '' : 'red'  }`"
            >
              <v-icon :color="entity.student_profiles_total <= entity.student_profiles_quota ? wsACCENT : 'red'">mdi-account-multiple</v-icon>
              {{ entity.student_profiles_total }} / {{ entity.student_profiles_quota }}
            </h5>
            <h5 v-if="entity.student_profiles_total > entity.student_profiles_quota" class="red--text  text-right" style="line-height: 1.2">
              Перебільшено на {{ entity.student_profiles_total - entity.student_profiles_quota }}
            </h5>
          </div>


        </template>
        <template #action.desktop.managers>

          <div class="text-no-wrap pr-6 ">
            <h5 class="text-no-wrap text-right "
                :style="`color : ${ entity.managers_total <= entity.managers_quota ? '' : 'red'  }`"
            >
              <v-icon :color="entity.managers_total <= entity.managers_quota ? wsACCENT : 'red'">mdi-account-tie</v-icon>
              {{ entity.managers_total }} / {{ entity.managers_quota }}
            </h5>
            <h5 v-if="entity.managers_total > entity.managers_quota" class="red--text  text-center" style="line-height: 1.2">
              Перебільшено на {{ entity.managers_total - entity.managers_quota }}
            </h5>
          </div>


        </template>
        <template #action.mobile.managers>

          <div class="px-6 pt-2 pl-13 pb-5">
            <h5 :style="`color : ${ entity.managers_total <= entity.managers_quota ? '' : 'red'  }`"
            >
              <v-icon :color="entity.managers_total <= entity.managers_quota ? wsACCENT : 'red'">mdi-account-tie</v-icon>
              {{ entity.managers_total }} / {{ entity.managers_quota }}
            </h5>
            <h5 v-if="entity.managers_total > entity.managers_quota" class="red--text " style="line-height: 1.2">
              Перебільшено на {{ entity.managers_total - entity.managers_quota }}
            </h5>
          </div>


        </template>

      </ws-accordion>
      <!-- Installed Modules Block-->
      <ws-accordion
          :items="modulesAccordionItems"
          class="mt-5 "
      >
        <template v-if="!SM" #action.modules>
          <!--        <h4 class="mr-6">{{ entity.modules ? entity.modules.length : 0 }} </h4>-->
        </template>
        <template #item.modules>

          <v-simple-table class="wsHoverLight">
            <tbody>
            <tr v-for="(module,j) in entity.modules" :key="'modules' + j">
              <td>
                <h4 class="">
                  <v-icon class="mr-2 ml-4" :color="wsSUCCESS">mdi-checkbox-marked-outline</v-icon>
                  {{ module.name }}</h4>
              </td>
            </tr>
            </tbody>
          </v-simple-table>

        </template>

      </ws-accordion>
      <div style="height: 50px"></div>
    </template>


    <!-- Change Client Plan Dialog-->
    <template #dialog>
      <wsDialog
          v-model="displayPlansDialog"
          :title="$t('ChangePaymentPlan')"
          width="1000"
          @save="changePlan"
          no-navigation >

        <div v-if="changePlanStep === 0">
          <h3 class="mb-3 text-center font-weight-medium"> Оберіть новий тарифний план:  </h3>
          <div class="d-flex flex-wrap justify-center">
            <v-sheet v-for="(plan,i) in clientPlansFiltered" :key="i"
                     @click="changePlanData.business_plan = plan.uuid"
                     :color="changePlanData.business_plan === plan.uuid ? wsDARK : wsLIGHTCARD"
                     :dark="changePlanData.business_plan === plan.uuid "
                     class="mt-6 mx-3 wsRoundedHalf pointer"
                     width="420">

              <v-sheet  dark :color="plan.color" elevation="0" class="wsRoundedTop py-1 px-6" />
              <v-row class="px-3">
                <v-col>
                  <div class="d-flex fill-height align-center">
                    <h4 class="text-center font-weight-medium mt-3">{{ plan.name }}</h4>
                  </div>
                </v-col>
                <v-col>
                  <h3 class="text-right font-weight-medium mt-1"> {{ plan.price }} $</h3>
                  <h6 class="text-right font-weight-light">{{ $t('perMonth') }}</h6>
                </v-col>
              </v-row>
              <v-divider class="my-3" />
              <v-row class="px-3 pb-3">
                <v-col cols="6">
                  <div class="d-flex fill-height align-center">
                    <div>
                      <h6 v-for="(module,j) in plan.modules" :key="i +'module' + j" class="font-weight-medium">
                        <v-icon small color="green lighten-1" class="mr-1">mdi-check</v-icon>{{ module.name }}</h6>
                    </div>
                  </div>

                </v-col>
                <v-col cols="6">

                  <div class="d-flex fill-height align-center">
                    <div>
                      <h6 class="font-weight-medium">
                        <v-icon small color="green lighten-1" class="mr-1">mdi-school </v-icon>
                        {{ $t('Courses') }}:  <span class="font-weight-bold"> {{ plan.quota_courses || $t('Unlimited')  }} </span>
                      </h6>
                      <h6 class="font-weight-medium">
                        <v-icon small color="green lighten-1" class="mr-1">mdi-account-school </v-icon>
                        {{ $t('StudentProfiles') }}:  <span class="font-weight-bold">{{  plan.quota_students  }}</span>
                      </h6>
                      <h6 class="font-weight-medium">
                        <v-icon small color="green lighten-1" class="mr-1">mdi-account-tie </v-icon>
                        {{ $t('Managers') }}:  <span class="font-weight-bold">{{  plan.quota_managers  }}</span>
                      </h6>
                      <h6 class="font-weight-medium">
                        <v-icon small color="green lighten-1" class="mr-1">mdi-database </v-icon>
                        {{ $t('Storage') }}:  <span class="font-weight-bold">{{  plan.quota_storage  }} GB</span>
                      </h6>
                    </div>
                  </div>

                </v-col>
              </v-row>

            </v-sheet>
          </div>
        </div>
        <div v-if="changePlanStep === 1" class="px-6">
          <h3 class="mb-6 text-center font-weight-medium">
            Зміна тарифного плану з {{ entity.business_plan_name }} на {{ selectedClientPlan.name }}
          </h3>
          <div v-if="getChangeCondition()">

            <div>
              <ws-chip class="mt-3" v-if="selectedClientPlan.quota_courses && (entity.courses_total - selectedClientPlan.quota_courses > 0 )"
                       :color="wsATTENTION" icon="mdi-information" >
                <h5> <span class="font-weight-light">
                Поточна кількість
                <span class="font-weight-bold">курсів</span>
                перевищує квоту обраного тарифу на</span>
                  {{ entity.courses_total - selectedClientPlan.quota_courses }}
                </h5>
              </ws-chip>

              <ws-chip class="mt-3" v-if="entity.student_profiles_total - selectedClientPlan.quota_students > 0 "
                       :color="wsATTENTION" icon="mdi-information" >
                <h5> <span class="font-weight-light">
                Поточна кількість
                <span class="font-weight-bold">студентів</span>
                перевищує квоту обраного тарифу на</span>
                  {{ entity.student_profiles_total - selectedClientPlan.quota_students }}
                </h5>
              </ws-chip>

              <ws-chip class="mt-3" v-if="entity.managers_total - selectedClientPlan.quota_managers > 0 "
                       :color="wsATTENTION" icon="mdi-information" >
                <h5> <span class="font-weight-light">
                Поточна кількість
                <span class="font-weight-bold">менеджерів</span>
                перевищує квоту обраного тарифу на</span>
                  {{ entity.managers_total - selectedClientPlan.quota_managers }}
                </h5>
              </ws-chip>

            </div>

            <ws-chip class="mt-3"
                     v-for="(module, k) in uninstalledModules" :key="'uninstalled' + k"
                     :color="wsATTENTION" icon="mdi-information" >
              <h5 class="font-weight-light"> Модуль поточного тарифу <span class="font-weight-bold">{{ module.name }}</span> не входить в обраний тариф та буде відключено </h5>
            </ws-chip>


          </div>
          <div v-if="getShowPriceCondition()" >
            <ws-chip :color="wsATTENTION" icon="mdi-information" >
              <h5 class="font-weight-light"> Для переходу на тарифний план необхідно здійснити доплату за різницю між тарифами. Новий тариф актувується з моменту оплати та діє до кінця оплаченого періоду </h5>
            </ws-chip>
            <ws-chip :color="wsATTENTION" icon="mdi-information" >
              <h5 class="font-weight-light"> Сумма доплати складає <span class="font-weight-bold"> {{  getChangePlanPrice()  }} USD </span>  </h5>
            </ws-chip>
          </div>
        </div>
        <div v-if="changePlanStep === 2">
          <h3 class="mb-6 text-center font-weight-medium pt-16 pb-8">
            Ви впевнені, що хочете понизити тарифний план? Операція остаточна, після пониження, повернутися на теперішний план можливо буде тільки після доплати
          </h3>
          <div class="d-flex justify-center">
            <v-btn small @click="changePlan"  outlined  class="noCaps mb-8 px-10" >{{ nextStepText }}</v-btn>
          </div>

        </div>

        <div class="d-flex justify-space-between align-center  mt-12">
          <div>
            <v-btn v-if="changePlanStep > 0" @click="previousStep" class="noCaps" outlined>{{ $t('GoBack') }}</v-btn>
          </div>
          <v-btn v-if="changePlanStep !== 2"
                 @click="nextStep"
                 :outlined="!getShowPriceCondition()  || changePlanStep !== 1"
                 :dark="getShowPriceCondition() && changePlanStep === 1"
                 :color="getShowPriceCondition() && changePlanStep === 1 ? 'green lighten-1' : ''"
                 class="noCaps"
          >{{ nextStepText }}</v-btn>
        </div>



      </wsDialog>
    </template>


  </sub-page>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "planAndPaymentsOverview",
  data() {
    return {
      changePlanStep : 0,
      displayPlansDialog : false,
      entity : {
        modules : []
      },
      entityData : {
        duration : 3,
      },
      discount : {
        1 : 0,
        3 : 0.05,
        6 : 0.07,
        12 : 0.1,
        24 : 0.15,
        36 : 0.2
      },
      changePlanData : {
        business_plan : ''
      }
    }
  },
  computed : {
    accordionItems() {
      return [
        { name : this.$t('Payment') ,
          subtitle : this.$t('BusinessPaymentDescription'),
          value : 'payment' ,
          icon : 'mdi-cash',
          expanded : true,
          noExpand : true
        },
      ]
    },
    modulesAccordionItems() {
      return [
        { name : this.$t('Modules') ,
          subtitle : this.$t('BusinessModulesDescription'),
          value : 'modules' ,
          icon : 'mdi-cogs',
          expanded : true,
        },
      ]
    },
    paymentPlanItems() {
      return [
        { name : this.$t('PaymentPlan') ,
          subtitle : this.$t('BusinessPaymentDescription'),
          value : 'paymentPlan' ,
          icon : 'mdi-cash',
          noExpand : true,
        },
        { name : this.$t('ActiveTill') ,
          subtitle : this.$t('BusinessActiveTillDescription'),
          value : 'date' ,
          icon : 'mdi-calendar',
          noExpand : true,
        },
        { name : this.$t('StudentProfiles') ,
          subtitle : this.$t('BusinessPaymentStudentsDescription'),
          value : 'students' ,
          icon : 'mdi-account-school',
          noExpand : true,
        },
        { name : this.$t('Managers') ,
          subtitle : this.$t('BusinessManagersStudentsDescription'),
          value : 'managers' ,
          icon : 'mdi-account-tie',
          noExpand : true,
        }
      ]
    },
    clientPlansFiltered() {
      if ( !this.entity.client_plans ) {
        return []
      }
      let items = this.entity.client_plans
      if ( !items.length ) {
        return []
      }
      if ( items.length === 0 ) {
        return []
      }

      items = items.filter( el => el.uuid !== this.entity.business_plan_id )
      items = items.filter( el => !el.alias.includes('trial') )
      items = items.filter( el => !el.alias.includes('social') )

      return items || []
    },
    paymentDurationSelect() {
      return [
        { text : `${this.$tc('month', 1  , { n : 1  }) }`      , value : 1  },
        { text : `${this.$tc('month', 3  , { n : 3  }) }, -5%`    , value : 3  },
        { text : `${this.$tc('month', 6  , { n : 6  }) }, -7%`    , value : 6  },
        { text : `${this.$tc('month', 12 , { n : 12 }) }, -10%`   , value : 12 },
        { text : `${this.$tc('month', 24 , { n : 24 }) }, -15%`   , value : 24 },
        { text : `${this.$tc('month', 36 , { n : 36 }) }, -20%`   , value : 36 }
      ]
    },
    selectedClientPlan() {
      if ( !this.changePlanData.business_plan ) {
        return {}
      }

      let plan = this.entity.client_plans.find( el => el.uuid === this.changePlanData.business_plan )
      if ( plan ) {
       return plan
      }

      return {}
    },
    uninstalledModules() {
      if ( !this.selectedClientPlan.modules ) {
        return []
      }
      if ( !this.entity.modules ) {
        return []
      }
      let newPlanModules = this.selectedClientPlan.modules.map(el => el.alias)
      return this.entity.modules.filter( el => !newPlanModules.includes(el.alias) )
    },
    nextStepText() {
      if ( this.changePlanStep === 1 ) {
        if ( this.getChangeCondition()) {
          return this.$t('ChangePaymentPlan')
        }
        if ( this.getShowPriceCondition()) {
          return this.$t('MakePayment')
        }
      }
      if ( this.changePlanStep === 2 ) {
        return this.$t('ChangePaymentPlan')
      }
      return this.$t('Next')
    }
  },
  methods : {
    ...mapActions('adminPayments', [
        'GET_INFO_FOR_BUSINESS',
        'CHECKOUT_BUSINESS_OWNER_PAYMENT_PLAN',
        'CHECKOUT_BUSINESS_OWNER_CHANGE_PLAN',
        'GET_PAYMENT_BUTTON'
    ]),

    openChangePlan() {

      this.changePlanStep = 0
      this.changePlanData = {
        business_plan : ''
      }
      this.displayPlansDialog = true
    },

    async checkout() {
      let order  = await this.CHECKOUT_BUSINESS_OWNER_PAYMENT_PLAN(this.entityData)
      if ( !order ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.getLiqpayButton(order)
    },
    async changePlan() {
      let result = await this.CHECKOUT_BUSINESS_OWNER_CHANGE_PLAN(this.changePlanData)
      if ( !result ) { return  }

      if (!result.order) {
        this.displayPlansDialog = false
        this.notify('BusinessPlanChanged')
        location.reload()
        return
      }

      this.getLiqpayButton(result.order)
      this.displayPlansDialog = false

    },

    nextStep() {
      if ( this.changePlanStep === 0 ) {
        if ( !this.changePlanData.business_plan ) {
          return this.notify(this.$t('BusinessPlanNotSelected'))
        }
        this.changePlanStep++
        return
      }
      if ( this.changePlanStep === 1 && this.getShowPriceCondition() ) {
        return this.changePlan()
      }
      if ( this.changePlanStep === 1 && this.getChangeCondition() ) {
        this.changePlanStep++
        return
      }
      if ( this.changePlanStep === 2 && this.getChangeCondition() ) {
        return this.changePlan()
      }
    },
    previousStep() {
      if ( this.changePlanStep > 0 ) {
        this.changePlanStep--;
      }
    },

    async getLiqpayButton(order) {

      let data = order
      order.description = this.getServiceText(order)
      order.return_url = location.href

      let button = await this.GET_PAYMENT_BUTTON(data)
      if ( !button || button === true ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.LIQPAY_checkout(button)
      this.displayEnroll = false
      this.displayEnrollSuccess = true

    },

    getServiceText(item) {

      if ( item.is_payment_plan) {
        return `${this.$t('Order')} №${item.order_id},  ${this.$t('PaymentForClientPlan')}: ${item.payment_plan_name} , ${this.$tc('month', item.duration_months, { n : item.duration_months })}`
      }
      if ( item.is_payment_plan_change) {
        return `${this.$t('Order')} №${item.order_id},  ${this.$t('PaymentForClientPlanChange')}: ${item.payment_plan_name} , ${this.$t('surchargeFor')}  ${this.$tc('day', item.duration_months, { n : item.duration_months })}`
      }


      return item.service
    },
    getChangeCondition() {
      if ( !this.changePlanData.business_plan ) {
        return false
      }
      let plan = this.entity.client_plans.find( el => el.uuid === this.changePlanData.business_plan )
      if ( plan ) {
        if ( plan.price < this.entity.business_plan_price) {
          return true
        }
      }
      return false;
    },
    getShowPriceCondition() {
      if ( !this.changePlanData.business_plan ) {
        return false
      }
      let plan = this.entity.client_plans.find( el => el.uuid === this.changePlanData.business_plan )
      if ( plan ) {
        if ( plan.price > this.entity.business_plan_price) {
          return true
        }
      }
      return false;
    },
    getChangePlanPrice() {

      if ( !this.changePlanData.business_plan ) {
        return false
      }
      let plan = this.entity.client_plans.find( el => el.uuid === this.changePlanData.business_plan )
      if ( plan ) {
        return Math.round( Math.abs(this.entity.days_left) * ( (plan.price - this.entity.business_plan_price)/30 ) )
      }
      return 0
    },

    getDiscountPrice() {
      return Math.round( ( this.entity.business_plan_price * this.discount[this.entityData.duration]) * this.entityData.duration )
    },
    getFinalPrice() {
      return Math.round( ( this.entity.business_plan_price * (1 - this.discount[this.entityData.duration])) * this.entityData.duration )
    },

    async initPage() {
      let result = await this.GET_INFO_FOR_BUSINESS()
      if ( !result ) { return }
      this.entity = result
    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>